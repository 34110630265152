import "./sideBar.css";
import dhwani from "../utils/dhwani.png";
import dhwaniText from "../utils/dhwanitext.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import {
  getFromLocalStorage,
  getStandards,
  getStreams,
  getSubSection,
} from "../services/functions";
import { MyContext } from "../provider";

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { setGrades, setSubjects, setSubSection, setChapters } =
    useContext(MyContext);

  const fetchStandards = async () => {
    const response = await getStandards();
    if (response.data.data.length > 0) {
      setGrades(response.data.data);
    }
  };

  const getStreamsById = async () => {
    const response = await getStreams(id);
    console.log("response>>>>>>>", response);
    setSubjects(response.data.data.subsections);
  };

  const getChapters = async () => {
    const response = await getSubSection(id);
    if (location.pathname.split("/").includes("chapters")) {
      setChapters(response.data.data.subsections);
    } else {
      setSubSection(response.data.data.subsections);
    }
  };

  useEffect(() => {
    const token = getFromLocalStorage("token");
    if (!token) {
      navigate("/");
    }
    if (location.pathname.split("/").includes("grades")) {
      fetchStandards();
    } else if (location.pathname.split("/").includes("subjects")) {
      getStreamsById();
    } else if (location.pathname.split("/").includes("chapters")) {
      getChapters();
    } else if (location.pathname.split("/").includes("subsection")) {
      getChapters();
    }
  }, [id]);

  return (
    <div className="sideBar-Main">
      <span className="sideBar-header">
        <span class="controller-header">
          <img src={dhwani} alt="not found" className="header-ImageSidebar" />
          <img
            src={dhwaniText}
            alt="not found"
            className="header-text-sidebar"
          />
        </span>
      </span>
      <span className="sidebar-Buttons">
        <button
          className="button-primary-sidebar"
          onClick={() => navigate("/grades")}
        >
          grades
        </button>
        <button
          className="button-primary-sidebar"
          onClick={() => navigate("/chapters")}
        >
          streams
        </button>
        <button
          className="button-primary-sidebar"
          onClick={() => navigate("/subjects")}
        >
          subjects
        </button>
      </span>
    </div>
  );
};

export default SideBar;
