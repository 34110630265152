import { useContext, useState } from "react";
import { MyContext } from "../../provider";
import { useNavigate } from "react-router-dom";
import CreateChapters from "../chapters/createChapter";

const SubSectionHeader = () => {
  const navigate = useNavigate();
  const { chapters } = useContext(MyContext);
  const handleNavigate = (e) => {
    navigate(`/subsection/${e.target.value}`);
  };
  const [subChapter, setSubChapter] = useState(false);
  return (
    <div className="gradesHeader-main">
      <span className="headerClasss">All Grades</span>
      <span className="selector-box">
        <select onChange={(e) => handleNavigate(e)}>
          <option hidden>Chapters</option>
          {chapters?.map((item) => {
            return <option value={item.id}>{item.name}</option>;
          })}
        </select>
      </span>
      <span>
        <input type="search" placeholder="search" className="search-Header" />
      </span>
      <span>
        <button
          type="button"
          className="button-Header"
          onClick={() => setSubChapter(true)}
        >
          + Subsection
        </button>
      </span>
      {subChapter && (
        <CreateChapters modal={subChapter} istoggle={() => setSubChapter()} />
      )}
    </div>
  );
};

export default SubSectionHeader;
