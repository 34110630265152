import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminLog from "./Pages/login";
import GradesIndex from "./Pages/Grades/gradesIndex";
import SubjectIndex from "./Pages/subjects/subjectIndex";
import ChapterIndex from "./Pages/chapters/chapterIndex";
import SubSection from "./Pages/Subsection/subSection";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AdminLog />} />
        <Route path="/grades" element={<GradesIndex />} />
        <Route path="/subjects/:id" element={<SubjectIndex />} />
        <Route path="/chapters/:id" element={<ChapterIndex />} />
        <Route path="/subsection/:id" element={<SubSection />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
