import { useState } from "react";
import "./grades.css";
import GradesModal from "./gradesModal";
const GradesHeader = () => {
  const [modal, setModal] = useState(false);
  return (
    <div className="gradesHeader-main">
      <span className="headerClasss">All Grades</span>
      <span>
        <input type="search" placeholder="search" className="search-Header" />
      </span>
      <div>
        <button className="button-Header" onClick={() => setModal(true)}>
          + New Grade
        </button>
      </div>
      {modal && <GradesModal modal istoggle={() => setModal()} />}
    </div>
  );
};

export default GradesHeader;
