import { useContext } from "react";
import CommonBody from "../../Components/commonBody";
import SideBar from "../../Components/sidebar";
import ChapterHeader from "./chapterHeader";
import { MyContext } from "../../provider";
import { useLocation } from "react-router-dom";

const ChapterIndex = () => {
  const { subSection, chapters } = useContext(MyContext);
  const location = useLocation();
  let response;
  if (location?.pathname?.split("/")?.includes("chapters")) {
    response = chapters;
  } else {
    response = subSection;
  }
  return (
    <div className="grades-page">
      <SideBar />
      <div className="header-body">
        <ChapterHeader />
        <CommonBody data={response} />
      </div>
    </div>
  );
};

export default ChapterIndex;
