import axios from "axios";
import config from "../config";

const sendAudio = (id, payload, name, description, status) => {
  console.log("payload>>>>>>>>", payload);
  let response;
  try {
    const url = `${config.cmnHeader}/media/${id}/medias?name=${name}&status=${status}&description=${description}`;
    const token = getFromLocalStorage("token");
    response = axios.post(url, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {
    console.log("in audio send>>>>>>>>>>", e);
  }
  return response;
};

const subChapters = (id, payload) => {
  let response;
  try {
    const url = `${config.cmnHeader}/subsections/${id}/chapters`;
    const token = getFromLocalStorage("token");
    response = axios.post(url, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {
    console.log("inCreategrades>>>>>>>>>>", e);
  }
  return response;
};

const createChapters = (id, payload) => {
  let response;
  try {
    const url = `${config.cmnHeader}/subsections/${id}/streams`;
    const token = getFromLocalStorage("token");
    response = axios.post(url, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {
    console.log("inCreategrades>>>>>>>>>>", e);
  }
  return response;
};

const createStreams = (payload, id) => {
  let response;
  try {
    const url = `${config.cmnHeader}/standards/${id}/streams`;
    const token = getFromLocalStorage("token");
    response = axios.post(url, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {
    console.log("inCreategrades>>>>>>>>>>", e);
  }
  return response;
};

const putSubSections = async (id, payload) => {
  let response;
  try {
    const url = `${config.cmnHeader}/subsections/${id}`;
    const token = getFromLocalStorage("token");
    response = await axios.put(url, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {
    response = e;
    console.log("Error:", e);
  }
  return response;
};

const putGrades = async (id, payload) => {
  let response;
  try {
    const url = `${config.cmnHeader}/standards/${id}`;
    const token = getFromLocalStorage("token");
    response = await axios.put(url, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {
    response = e;
    console.log("Error:", e);
  }
  return response;
};
const deleteSubSection = async (id) => {
  let response;
  try {
    const url = `${config.cmnHeader}/subsections/${id}`;
    const token = getFromLocalStorage("token");
    response = await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {
    console.log("e", e);
  }
  return response;
};

const deleteStandards = async (id) => {
  let response;
  try {
    const url = `${config.cmnHeader}/standards/${id}`;
    const token = getFromLocalStorage("token");
    response = await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {
    response = e;
    console.log("e", e);
  }
  return response;
};

const getAudio = async (id) => {
  let response;
  try {
    const url = `${config.cmnHeader}/public/medias/${id}`;
    const token = getFromLocalStorage("token");
    response = await axios({
      method: "get",
      url,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    console.log(e);
    response = e;
  }
  return response;
};

const createGrades = (payload) => {
  let response;
  try {
    const url = `${config.cmnHeader}/standards`;
    const token = getFromLocalStorage("token");
    response = axios.post(url, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } catch (e) {
    console.log("inCreategrades>>>>>>>>>>", e);
  }
  return response;
};

const getSubSection = async (id) => {
  let response;
  try {
    const url = `${config.cmnHeader}/subsections/${id}`;
    const token = getFromLocalStorage("token");
    response = await axios({
      method: "get",
      url,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    console.log(e);
    response = e;
  }
  return response;
};

const getStreams = (id) => {
  let response;
  try {
    const url = `${config.cmnHeader}/standards/${id}`;
    const token = getFromLocalStorage("token");
    response = axios({
      method: "get",
      url,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    console.log("e", e);
    response = e;
  }
  return response;
};

const getStandards = async () => {
  let response;
  const token = getFromLocalStorage("token");
  try {
    const url = `${config.cmnHeader}/standards`;
    response = await axios({
      method: "get",
      url,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
  } catch (e) {
    response = e;
    console.log("e>>>", e);
  }
  return response;
};

const postLogin = async (payload) => {
  let response;
  try {
    const url = `${config.cmnHeader}/admin/login`;
    response = await axios.post(url, payload);
  } catch (e) {
    console.log(e);
    response = e;
  }
  return response;
};

const setToLocalStorage = (key, data) => {
  try {
    localStorage.setItem(key, data);
  } catch (e) {
    console.log("eeee>>>>", e);
  }
};

const getFromLocalStorage = (key) => {
  let item;
  try {
    item = localStorage.getItem(key);
  } catch (e) {
    console.log(e);
  }
  return item;
};

export {
  putSubSections,
  putGrades,
  createGrades,
  postLogin,
  setToLocalStorage,
  getFromLocalStorage,
  getStandards,
  getStreams,
  getSubSection,
  getAudio,
  deleteStandards,
  deleteSubSection,
  createStreams,
  createChapters,
  subChapters,
  sendAudio,
};
