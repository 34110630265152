import { useContext, useState } from "react";
import { MyContext } from "../../provider";
import { useNavigate } from "react-router-dom";
import CreateChapters from "./createChapter";

const ChapterHeader = () => {
  const navigate = useNavigate();
  const { subjects } = useContext(MyContext);
  const [chapters, setChapters] = useState(false);
  const handleNavigate = (e) => {
    navigate(`/chapters/${e.target.value}`);
  };
  return (
    <div className="gradesHeader-main">
      <span className="headerClasss">All Grades</span>
      <span className="selector-box">
        <select onChange={(e) => handleNavigate(e)}>
          <option hidden>Streams</option>
          {subjects?.map((item) => {
            return <option value={item.id}>{item.name}</option>;
          })}
        </select>
      </span>
      <span>
        <input type="search" placeholder="search" className="search-Header" />
      </span>
      <span>
        <button
          type="button"
          className="button-Header"
          onClick={() => setChapters(true)}
        >
          + Chapter
        </button>
      </span>
      {chapters && (
        <CreateChapters modal={chapters} istoggle={() => setChapters()} />
      )}
    </div>
  );
};

export default ChapterHeader;
