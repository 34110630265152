import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./audio.css";
import {
  deleteStandards,
  deleteSubSection,
  getStandards,
  getStreams,
  getSubSection,
} from "../services/functions";
import { useLocation, useParams } from "react-router-dom";
import { useContext } from "react";
import { MyContext } from "../provider";

const DeleteModal = ({ modal, isToggle, itemId }) => {
  const location = useLocation();
  const { id } = useParams();

  const { setGrades, setSubjects, setChapters, setSubSection } =
    useContext(MyContext);
  const sendDelete = async () => {
    if (location.pathname.split("/").includes("grades")) {
      const response = await deleteStandards(itemId._id);
      const responseGrades = await getStandards();
      if (responseGrades.data.data.length > 0) {
        setGrades(responseGrades.data.data);
      }
    } else {
      const response = await deleteSubSection(itemId.id);
      if (location.pathname.split("/").includes("subjects")) {
        const responseGrades = await getStreams(id);
        if (responseGrades.data.data.subsections) {
          setSubjects(responseGrades.data.data.subsections);
        }
      } else {
        const response = await getSubSection(id);
        if (location.pathname.split("/").includes("chapters")) {
          setChapters(response?.data?.data?.subsections);
        } else {
          setSubSection(response?.data?.data?.subsections);
        }
      }
    }
    isToggle({ state: !modal, data: "" });
  };
  return (
    <Modal isOpen={modal} className="modal-controller">
      <ModalHeader className="modal-header">Delete</ModalHeader>
      <ModalBody className="modal-body">Are you sure to delete?</ModalBody>
      <span>
        <button onClick={() => sendDelete()}>delete</button>
        <button onClick={() => isToggle({ state: !modal, data: "" })}>
          cancel
        </button>
      </span>
    </Modal>
  );
};

export default DeleteModal;
