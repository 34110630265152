import "./grades.css";
import SideBar from "../../Components/sidebar";
import GradesHeader from "./gradesHeader";
import CommonBody from "../../Components/commonBody";
import { MyContext } from "../../provider";
import { useContext } from "react";

const GradesIndex = () => {
  const { grades } = useContext(MyContext);
  const x = [
    { name: "hello" },
    { name: "hello" },
    { name: "hello" },
    { name: "hello" },
  ];
  return (
    <div className="grades-page">
      <SideBar />
      <div className="header-body">
        <GradesHeader />
        <CommonBody data={grades} />
      </div>
    </div>
  );
};

export default GradesIndex;
