import { useState, useEffect } from "react";
import "./login.css";
import dhwani from "../utils/dhwani.png";
import dhwaniText from "../utils/dhwanitext.svg";
import { postLogin, setToLocalStorage } from "../services/functions";
import { useNavigate } from "react-router-dom";
const AdminLog = () => {
  const navigate = useNavigate();

  const [text, setText] = useState();
  const [password, setPassword] = useState();

  const createText = (e) => {
    setText(e.target.value);
  };

  const createPassword = (e) => {
    setPassword(e.target.value);
  };

  const sentLogin = async () => {
    const payload = {
      username: text,
      password,
    };
    const response = await postLogin(payload);
    if (response.status === 200) {
      setToLocalStorage("token", response?.data?.access_token);
      navigate("/grades");
    }
  };

  return (
    <div className="login-bg">
      <div className="login-header">
        <img src={dhwani} alt="not found" className="image-1" />
        <img src={dhwaniText} alt="not found" className="image-2" />
      </div>
      <div className="login-box">
        <div className="login-box-style">
          <input
            type="text"
            className="login-text-box1"
            onChange={(e) => createText(e)}
          />
          <input
            type="passsword"
            className="login-text-box2"
            onChange={(e) => createPassword(e)}
          />
          <div className="forgot-password">
            <div>forgot password?</div>
          </div>
          <div className="submit-button-box">
            <button
              type="submit"
              className="loginSubmit"
              onClick={() => sentLogin()}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLog;
