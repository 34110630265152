import "./subject.css";
import SideBar from "../../Components/sidebar";
import CommonBody from "../../Components/commonBody";
import { MyContext } from "../../provider";
import { useContext } from "react";
import SubjectHeader from "./subjectHeader";

const SubjectIndex = () => {
  const { subjects } = useContext(MyContext);
  return (
    <div className="grades-page">
      <SideBar />
      <div className="header-body">
        <SubjectHeader />
        <CommonBody data={subjects} />
      </div>
    </div>
  );
};

export default SubjectIndex;
