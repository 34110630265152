import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "./audio.css";
import { useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  getStandards,
  getStreams,
  getSubSection,
  putGrades,
  putSubSections,
} from "../services/functions";
import { MyContext } from "../provider";

const EditModal = ({ modal, isToggle, itemId }) => {
  console.log("Item.id>>>>>>>", itemId);
  const { id } = useParams();
  const location = useLocation();
  const [payload, setPayload] = useState({ name: "" });
  const { setGrades, setChapters, setSubSection, setSubjects } =
    useContext(MyContext);
  const getPayload = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };
  const sentUpdate = async () => {
    if (location.pathname.split("/").includes("grades")) {
      const data = {
        name: payload.name,
        name_in_voice: itemId?.name_in_voice,
        status: itemId?.status,
        subSections: [...itemId?.subsections],
        stream_included: itemId?.stream_included,
      };
      const response = await putGrades(itemId._id, data);
      if (response.data.status_code === 200) {
        const response = await getStandards();
        if (response.data.data.length > 0) {
          setGrades(response.data.data);
        }
      }
    } else if (location.pathname.split("/").includes("subjects")) {
      const data = {
        name: payload.name,
        name_in_voice: itemId?.name_in_voice,
        status: itemId?.status,
        subSections: [...itemId?.subsections],
        stream_included: itemId?.stream_included,
      };
      const response = await putGrades(itemId.id, data);
      if (response) {
        const response = getStreams(id);
        if (response?.data?.data?.subsections) {
          setSubjects(response?.data?.data?.subsections);
        }
      }
    } else if (
      location.pathname.split("/").includes("chapters") ||
      location.pathname.split("/").includes("subsection")
    ) {
      const data = {
        name: payload.name,
        description: itemId?.description,
        subsections: [...itemId?.subsections],
        status: itemId?.status,
        type: itemId?.type,
      };
      console.log("data>>>>>>>>", data);
      const response = await putSubSections(itemId.id, data);
      if (response) {
        const response = await getSubSection(id);
        if (location.pathname.split("/").includes("chapters")) {
          setChapters(response.data.data.subsections);
        } else {
          setSubSection(response.data.data.subsections);
        }
      }
    }
    isToggle({ state: !modal, data: "" });
  };

  return (
    <Modal className="modal-controller" isOpen={modal}>
      <ModalHeader className="modal-header">Edit Data</ModalHeader>
      <ModalBody>
        <span>
          <label>Name</label>
          <input type="text" onChange={(e) => getPayload(e)} name="name" />
        </span>
      </ModalBody>
      <span>
        <button onClick={() => sentUpdate()}>Update</button>
        <button onClick={() => isToggle({ state: !modal, data: "" })}>
          Cancel
        </button>
      </span>
    </Modal>
  );
};

export default EditModal;
