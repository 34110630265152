import { useContext, useEffect, useState } from "react";
import "./subject.css";
import { MyContext } from "../../provider";
import { useNavigate } from "react-router-dom";
import NewSubjectModal from "./newSubjectModal";
const SubjectHeader = () => {
  const navigate = useNavigate();
  const { grades } = useContext(MyContext);
  const handleNavigate = (e) => navigate(`/subjects/${e.target.value}`);
  const [newSubject, setNewSubject] = useState(false);

  return (
    <div className="gradesHeader-main">
      <span className="headerClasss">All Grades</span>
      <span className="selector-box">
        <select onChange={(e) => handleNavigate(e)}>
          <option hidden>grades</option>
          {grades.map((item) => {
            return <option value={item._id}>{item.name}</option>;
          })}
        </select>
      </span>
      <span>
        <input type="search" placeholder="search" className="search-Header" />
      </span>
      <span>
        <button
          type="button"
          className="button-Header"
          onClick={() => setNewSubject(true)}
        >
          + Subject
        </button>
        {true && (
          <NewSubjectModal
            modal={newSubject}
            istoggle={() => setNewSubject()}
          />
        )}
      </span>
    </div>
  );
};

export default SubjectHeader;
