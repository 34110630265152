import { createContext, useState } from "react";
export const MyContext = createContext();
const MyProvider = ({ children }) => {
  const [subjects, setSubjects] = useState();
  const [grades, setGrades] = useState([]);
  const [subSection, setSubSection] = useState([]);
  const [chapters, setChapters] = useState([]);
  return (
    <MyContext.Provider
      value={{
        grades,
        setGrades,
        subjects,
        setSubjects,
        subSection,
        setSubSection,
        chapters,
        setChapters,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export default MyProvider;
