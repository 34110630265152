import { useRef, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { sendAudio } from "../services/functions";

const AddAudio = ({ modal, isToggle, itemId }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [payload, setPayload] = useState({ name: "", description: "" });
  const setName = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };
  const checkRef = useRef();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    // Check if the selected file is an audio file
    if (file && file.type.startsWith("audio/")) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      console.log("Please select a valid audio file.");
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      // Perform upload operation here, for demonstration, just log the file
      const formData = new FormData();
      const ref = checkRef.current.checked ? "public" : "private";
      formData.append("file", selectedFile);
      sendAudio(itemId, formData, payload.name, payload.description, ref);
    } else {
      console.log("No audio file selected");
    }
  };

  return (
    <Modal isOpen={modal} className="modal-controller">
      <ModalHeader>Add Audio</ModalHeader>
      <ModalBody className="modal-body">
        <span className="ps-1">
          <label>Name: </label>
          <input
            type="text"
            className="input-modal"
            name="name"
            onChange={(e) => setName(e)}
          />
        </span>
        <span className="ps-1">
          <label>Name in voice: </label>
          <input
            type="text"
            className="input-modal"
            onChange={(e) => setName(e)}
            name="description"
          />
        </span>
        <span className="ps-1">
          <label>Public</label>
          <input type="checkbox" ref={checkRef} />
        </span>
        <div>
          <input type="file" accept="audio/*" onChange={handleFileChange} />
        </div>
      </ModalBody>
      <ModalFooter>
        <button onClick={handleUpload} disabled={!selectedFile}>
          Upload
        </button>
        <button onClick={() => isToggle({ state: !modal, data: "" })}>
          close
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default AddAudio;
