import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import "../Grades/grades.css";
import { useState, useRef, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import { MyContext } from "../../provider";
import { getSubSection, subChapters } from "../../services/functions";

const CreateChapters = ({ modal, istoggle }) => {
  const { setChapters, setSubSection } = useContext(MyContext);
  const checkRef = useRef();
  const { id } = useParams();
  const [payload, setPayload] = useState({ name: "", description: "" });
  const location = useLocation();
  const setName = (e) => {
    const { name, value } = e.target;
    setPayload({ ...payload, [name]: value });
  };
  const sentData = async () => {
    const ogPayload = {
      ...payload,
      status: checkRef.current.checked ? "public" : "private",
      subsections: [],
    };
    const response = await subChapters(id, ogPayload);
    if (response) {
      const response = await getSubSection(id);
      if (location.pathname.split("/").includes("chapters")) {
        setChapters(response.data.data.subsections);
      } else {
        setSubSection(response.data.data.subsections);
      }
    }
    istoggle(!modal);
  };
  return (
    <Modal
      isOpen={modal}
      toggle={() => istoggle(!modal)}
      size="sm"
      className="modal-controller"
    >
      <ModalHeader className="modal-header">Add New Grade </ModalHeader>
      <ModalBody className="modal-body">
        <span className="ps-1">
          <label>Name: </label>
          <input
            type="text"
            className="input-modal"
            name="name"
            onChange={(e) => setName(e)}
          />
        </span>
        <span className="ps-1">
          <label>Name in voice: </label>
          <input
            type="text"
            className="input-modal"
            onChange={(e) => setName(e)}
            name="description"
          />
        </span>
        <span className="ps-1">
          <label>Public</label>
          <input type="checkbox" ref={checkRef} />
        </span>
      </ModalBody>
      <ModalFooter className="gades-footer">
        <div className="grades-footer-btn">
          <button onClick={() => sentData()} className="modal-btn-add">
            Add
          </button>
          <button onClick={() => istoggle(!modal)} className="modal-btn-cancel">
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default CreateChapters;
