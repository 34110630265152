import { useLocation, useNavigate } from "react-router-dom";
import "./commonBody.css";
import { useEffect, useState } from "react";
import AudioModal from "./audioModal";
import { getAudio } from "../services/functions";
import { Trash, Edit, Upload } from "react-feather";
import DeleteModal from "./deleteModal";
import EditModal from "./editModal";
import AddAudio from "./addAudio";
const CommonBody = ({ data }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const [audioModal, setAudioModal] = useState(false);
  // const [audio, setAudio] = useState({ state: false, data: "" });
  const [deleteId, setDeleteModal] = useState({ state: false, data: "" });
  const [editModal, setEditModal] = useState({ state: false, data: "" });
  const [addAudio, setAddAudio] = useState({ state: false, data: "" });

  const fetchMore = async (item) => {
    if (location.pathname.split("/").includes("grades")) {
      navigate(`/subjects/${item._id}`);
    } else if (location.pathname.split("/").includes("subjects")) {
      navigate(`/chapters/${item.id}`);
    } else if (location.pathname.split("/").includes("chapters")) {
      navigate(`/subsection/${item.id}`);
    } else if (location.pathname.split("/").includes("subsection")) {
      // const response = await getAudio(item.id);
      // setAudio(response.data);
      // setAudioModal(true);
      console.log("item>>>>>", item);
      if (item.type === "chapter") {
        navigate(`/subsection/${item.id}`);
      }
      // else {
      //   setAddAudio({ state: true, data: item });
      // }
    }
  };

  return (
    <div className="card-body-parent">
      {data?.map((item) => {
        return (
          <span className="card-Body">
            <span className="icon-common">
              <Edit onClick={() => setEditModal({ state: true, data: item })} />
              {location.pathname.split("/").includes("subsection") &&
                item.type === "chapter" && (
                  <Upload
                    onClick={() => setAddAudio({ state: true, data: item })}
                  />
                )}
              <Trash
                onClick={() => setDeleteModal({ state: true, data: item })}
              />
            </span>
            <div onClick={() => fetchMore(item)} className="main-body">
              <label>{item.name}</label>
            </div>
          </span>
        );
      })}
      {/* {audioModal && (
        <AudioModal
          modal={audioModal}
          isToggle={() => setAudioModal()}
          data={audio}
        />
      )} */}
      {deleteId?.state && (
        <DeleteModal
          modal={deleteId?.state}
          isToggle={() => setDeleteModal()}
          itemId={deleteId.data}
        />
      )}
      {editModal?.state && (
        <EditModal
          modal={editModal.state}
          isToggle={() => setEditModal()}
          itemId={editModal.data}
        />
      )}
      {addAudio?.state && (
        <AddAudio
          modal={addAudio?.state}
          isToggle={() => setAddAudio()}
          itemId={addAudio?.data?.id}
        />
      )}
    </div>
  );
};

export default CommonBody;
