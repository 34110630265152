import CommonBody from "../../Components/commonBody";
import SideBar from "../../Components/sidebar";
import SubSectionHeader from "./subSectionHeader";
import "./subSection.css";
import { useContext } from "react";
import { MyContext } from "../../provider";

const SubSection = () => {
  const { subSection } = useContext(MyContext);
  return (
    <div className="grades-page">
      <SideBar />
      <div className="header-body">
        <SubSectionHeader />
        <CommonBody data={subSection} />
      </div>
    </div>
  );
};

export default SubSection;
